<!-- qa问题搜索没有结果 -->
<template>
  <div>
    <headBar />
    <searchModel />
    <div class="backGroundBg">
      <div class="con">
        <div class="titleBox">
          <span @click="$fun.routerToPage('/questionAndAnswer')">
            {{ $fanyi("帮助中心") }}
          </span>
        </div>
        <div class="searchConBox">
          <div class="searchBoxRight">
            <h1>検索結果: {{ $route.query.s.replace("+", " ") }}</h1>
            <hr />
            <h2>ご希望の内容がみつかりませんでした</h2>
            <p>
              別のキーワードでも見つからない場合は <br />
              カスタマーサポートまたは担当者までご連絡ください。
            </p>
          </div>
        </div>
        <div class="linkUs">
          <img :src="require('@/assets/icon/hongseliaotian.png')" alt="" />
        </div>
      </div>
    </div>
    <footbar />
  </div>
</template>
<script>
import headBar from "@/components/headBar/index.vue";

import footbar from "@/components/footBar/index.vue";
import searchModel from "./components/searchModel.vue";
export default {
  data() {
    return {
      page: 1,
      searchArr: [],
      showList: [],
    };
  },
  components: {
    headBar,
    footbar,
    searchModel,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.backGroundBg {
  background-color: white;
  padding-top: 20px;
  .con {
    margin: 0 auto;
    .titleBox {
      padding: 50px 40px 40px;
      display: flex;
      flex-wrap: wrap;
      * {
        font-size: 20px;
        line-height: 30px;
      }
      .pageNameSpan {
        color: #7a7a7a;
      }
      .van-icon-arrow {
        margin: 0 20px;
        color: #1a289d;
      }
    }
    .searchConBox {
      padding: 0 40px 0px;
      display: flex;
      .searchBoxRight {
        margin-bottom: 70px;
        flex: 1;
        h1 {
          font-size: 32px;
          font-weight: bold;
          line-height: 48px;
          margin-bottom: 30px;
        }
        hr {
          height: 1px;
          margin: 30px 0 80px;
          background-color: #d7d7d7;
        }
        h2 {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          text-align: center;
          margin-bottom: 30px;
        }
        p {
          font-size: 20px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
    .linkUs {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #b4272b;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      right: 30px;
      bottom: 180px;
      z-index: 2;
      img {
        width: 40px;
        height: 34px;
      }
    }
  }
}
</style>
